@import "~antd/dist/antd.css";

@tailwind base;

img {
	object-fit: contain;
}
input {
	background-color: transparent !important;
}
.ant-spin-dot-item {
	background-color: #000;
}
.ant-switch-handle {
	top: 1px;
}
.ant-switch {
	background-color: rgb(155, 0, 0);
	border: 0.1rem solid rgba(255, 255, 255, 0.224);
}
.ant-switch-checked {
	background-color: rgb(0, 105, 4);
}

.ant-collapse-header-text {
	color: white;
	font-size: 20px;
}

.card-bg {
	background: linear-gradient(
		179.6deg,
		#111111 0.34%,
		rgba(40, 39, 39, 0) 126.43%
	);
}

.side-bg {
	background: rgb(0, 0, 0);
	background: -moz-linear-gradient(
		180deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(131, 118, 118, 1) 100%
	);
	background: -webkit-linear-gradient(
		180deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(131, 118, 118, 1) 100%
	);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 1) 0%,
		rgba(131, 118, 118, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#837676",GradientType=1);
}

.side-border {
	background: rgb(31, 30, 30);
	background: -moz-linear-gradient(
		180deg,
		rgba(31, 30, 30, 1) 0%,
		rgba(165, 165, 165, 1) 100%
	);
	background: -webkit-linear-gradient(
		180deg,
		rgba(31, 30, 30, 1) 0%,
		rgba(165, 165, 165, 1) 100%
	);
	background: linear-gradient(
		180deg,
		rgba(31, 30, 30, 1) 0%,
		rgba(165, 165, 165, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#1f1e1e",endColorstr="#a5a5a5",GradientType=1);
}

.main-bg {
	background: rgb(164, 64, 84);
	background: -moz-radial-gradient(
		circle,
		rgba(164, 64, 84, 1) 0%,
		rgba(45, 50, 56, 1) 55%,
		rgba(23, 43, 45, 1) 71%,
		rgba(41, 54, 57, 1) 100%
	);
	background: -webkit-radial-gradient(
		circle,
		rgba(164, 64, 84, 1) 0%,
		rgba(45, 50, 56, 1) 55%,
		rgba(23, 43, 45, 1) 71%,
		rgba(41, 54, 57, 1) 100%
	);
	background: radial-gradient(
		circle,
		rgba(164, 64, 84, 1) 0%,
		rgba(45, 50, 56, 1) 55%,
		rgba(23, 43, 45, 1) 71%,
		rgba(41, 54, 57, 1) 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a44054",endColorstr="#293639",GradientType=1);
}

.ant-upload.ant-upload-drag {
	border: 1px solid #444;
	background: linear-gradient(
		179.6deg,
		#111111 0.34%,
		rgba(40, 39, 39, 0) 126.43%
	);
}

.ant-input[disabled] {
	background-color: transparent;
	--tw-text-opacity: 1;
	color: rgb(156 163 175 / var(--tw-text-opacity));
}

.select-page {
	background: #233235;
}
.rdt_TableHeadRow {
	font-size: 20px;
}
.rdt_TableCell {
	font-size: 14px;
}
.rdt_TableHeadRow span {
	margin: 4px;
	font-size: 8px;
}
.rdt_TableCol_Sortable {
	display: none;
}
@tailwind components;
@tailwind utilities;

@layer base {
	[type="text"],
	[type="email"],
	[type="url"],
	[type="password"],
	[type="number"],
	[type="radio"],
	[type="date"],
	[type="datetime-local"],
	[type="month"],
	[type="search"],
	[type="tel"],
	[type="time"],
	[type="week"],
	[multiple],
	textarea,
	select {
		@apply px-2 py-2 rounded-sm focus:ring-gray-400 focus:border-gray-400;
	}
}

@layer components {
	.test {
		@apply border;
	}

	.vertical-scroll {
		@apply scrollbar scrollbar-thin hover:scrollbar-thumb-gray-500 scrollbar-thumb-gray-200;
	}

	.btn-border {
		@apply px-2 py-1 text-white border border-gray-100 rounded-lg cursor-pointer;
	}
}

select {
	background-position: calc(100% - 0.75rem) center !important;
	-moz-appearance: none !important;
	-webkit-appearance: none !important;
	appearance: none;
	background-image: none;
	background-color: #000;
}

.react-datetimerange-picker__wrapper,
.react-datetimerange-picker__clear-button__icon,
.react-datetimerange-picker__button__icon,
.ant-collapse-expand-icon {
	stroke: white;
	color: #fff !important;
	background-color: rgba(255, 255, 255, 0);
}

.grsa-Dj svg {
	top: 20% !important;
}
.react-datetimerange-picker__range-divider {
	margin: 0px 10px;
}
.dropdown-menu p {
	color: #fff;
	font-size: 14px;
	animation: slide 1s ease-in-out forwards;
}
@keyframes slide {
	from {
		height: 15px;
		display: none;
	}
	to {
		height: 20px;
		display: flex;
	}
}
.canvasjs-chart-credit {
	display: none !important;
}

.cluster-marker {
	color: #fff;
	background: #1978c8;
	border-radius: 50%;
	padding: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.user-marker {
	background: none;
	border: none;
}

.user-marker img {
	width: 25px;
}

.user-marker:hover .user-tooltip-map {
	display: flex;
	animation: showToolTip 0.2s ease-in-out forwards;
}
@keyframes showToolTip {
	from {
		width: 0px;
		display: none;
	}
	to {
		width: 100px;
		display: flex;
	}
}
.ant-select-arrow {
	color: white;
}
